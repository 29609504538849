import { notification, Button } from "antd";

const notificationFunc = (type, title, desc) => {
  desc = desc || "";
  notification[type]({
    message: `${title}`,
    description: `${desc}`,
  });
};

export default notificationFunc;
