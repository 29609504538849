import React from "react";

const HomeSeventhPart = () => {
  return (
    <div className="seventh-part">
      <div className="container">
        <div className="top-divider"></div>
        <div className="main-text">
          “Mi ne prodajemo samo proizvod, već doživljaj, iskustvo i uslugu.
          Poklanjamo znanje i umeće kombinovanja ukusa, ne bi li dali ljudima
          priliku da otputuju na drugi kraj Sveta.
          <br /> Maštamo i kreiramo”
        </div>
        <div className="bottom-divider"></div>
      </div>
    </div>
  );
};

export default HomeSeventhPart;
