import React from "react";
import { Popover, Button } from "antd";
import { TiInfoLargeOutline } from "react-icons/ti";
import { ButtonCustom, ButtonCustom2, CustomPopover } from "./BurCompStyle";

const BuritoComp = ({ dataBur }) => {
  const content = (
    <div>
      <p>{dataBur.desc}</p>
    </div>
  );
  return (
    <div className="component-bur">
      <img className="slika-sastojci-burito-page" src={dataBur.source}></img>
      <div className="container sastojak-info">
        <h5 className="sastojak-ime">{dataBur.nameIng}</h5>
        <CustomPopover
          placement="bottomLeft"
          content={content}
          title={dataBur.nameIng}
          trigger="hover"
        >
          <ButtonCustom2 className="btn-hover-icon">
            <TiInfoLargeOutline />
          </ButtonCustom2>
        </CustomPopover>
      </div>
    </div>
  );
};

export default BuritoComp;
