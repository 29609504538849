import {
  CONTACT_FORM_SUCCESS,
  CONTACT_FORM_FAIL,
  CONTACT_FORM_LOADING,
  JOB_APPLY_SUCCESS,
  JOB_APPLY_FAIL,
  JOB_APPLY_LOADING,
} from "../actions/types";

const initialState = {
  name: "",
  surname: "",
  phone: "",
  email: "",
  message: "",
  loading: false,
  failed: false,
  http_response: {},
};

export default function (state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case CONTACT_FORM_LOADING:
      return {
        ...state,
        loading: true,
        http_response: payload,
      };
    case CONTACT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        http_response: payload,
      };
    case CONTACT_FORM_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        http_response: payload,
        error: error,
      };
    case JOB_APPLY_LOADING:
      return {
        ...state,
        loading: true,
        http_response: payload,
      };
    case JOB_APPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        http_response: payload,
      };
    case JOB_APPLY_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        http_response: payload,
        error: error,
      };

    default:
      return { ...state };
  }
}
