import React, { useState, useEffect } from "react";
import middlephoto from "../assets/mainpage/middlepage.jpg";
// import slika1 from "../assets/mainpage/slika11.jpg";
// import slika2 from "../assets/mainpage/slika4.jpg";
// import slika3 from "../assets/mainpage/slika9.jpg";
import g from "../assets/mainpage/g.jpg";
import aleksa from "../assets/mainpage/aleksa.png";
import nemanja from "../assets/mainpage/nemanja.png";
import lucija from "../assets/mainpage/lucija.png";

import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Carousel } from "antd";
import Stars from "./Stars";

const HomeFifthPart = () => {
  useEffect(() => {
    setTimeout(() => {}, 1000);
  }, []);

  const [valueofsubmit, setValueofsubmit] = useState(false);

  const contentStyle = {
    height: "100vh",
    color: "#fff",
    // lineHeight: "400px",
    textAlign: "center",
    background: "#62929e",
  };

  const contentStyle2 = {
    height: "100vh",
    color: "#fff",
    textAlign: "center",
    background: "#fff",
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <AiOutlineArrowRight
        className={className}
        style={{
          ...style,
          right: "0",
          display: "block",
          color: "#f6f6f4",
          background: "rgba(0, 0 , 0, 0.1)",
          width: "20px",
          height: "40px",
          padding: "5px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <AiOutlineArrowLeft
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#f6f6f4",
          background: "rgba(0, 0 , 0, 0.1)",
          width: "20px",
          height: "40px",
          padding: "5px",
          zIndex: "3",
          top: "50%",
          transform: "translateY(-50%)",
          left: "0",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplaySpeed: 3500,
    dots: false,
    height: "100%",
  };

  const carouselRef = React.createRef();

  const [carouselChange, setChange] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      carouselRef && carouselRef.current && carouselRef.current.next();
      setChange(carouselChange + 1);
    }, 3000);
  }, [carouselChange]);
  return (
    <div className="footer-carousel-div">
      <h4 className="rec-title">Recenzije i komentari</h4>

      <Carousel
        autoplay={true}
        arrows
        {...settings}
        ref={carouselRef}
        className="carousel-recenzije-2"
      >
        <div className="carousel-footer-single-div">
          <div className="rec-image-name">
            <div className="rec-image">
              <img src={g} />
            </div>
            <div className="rec-name">
              <span>Dragana Mladenović</span>
              <br />
              <span>24</span>
            </div>
          </div>

          <div className="divider"></div>
          <a target="_blank" href="https://g.co/kgs/j7ebk7">
            <Stars />
          </a>
          <div className="rec-text">
            <p>“Najbolja klopa u gradu, uvek sveže i kvalitetno.”</p>
          </div>
        </div>
        <div className="carousel-footer-single-div">
          <div>
            <div className="rec-image-name">
              <div className="rec-image">
                <img src={aleksa} />
              </div>
              <div className="rec-name">
                <span>Aleksa Živanović</span>
                <br />
                <span>26</span>
              </div>
            </div>

            <div className="divider"></div>
            <a target="_blank" href="https://g.co/kgs/j7ebk7">
              <Stars />
            </a>
            <div className="rec-text">
              <p>“Najbolja meksička hrana u Nišu”</p>
            </div>
          </div>
        </div>
        <div className="carousel-footer-single-div">
          <div>
            <div className="rec-image-name">
              <div className="rec-image">
                <img src={nemanja} />
              </div>
              <div className="rec-name">
                <span>Nemanja Bjelica</span>
                <br />
                <span>28</span>
              </div>
            </div>
            <div className="divider"></div>
            <div>
              <a target="_blank" href="https://g.co/kgs/yrcQHH">
                <Stars />
              </a>
            </div>
            <div className="rec-text">
              <p>“Izuzetno dobra hrana po povoljnoj ceni, preporučujem.”</p>
            </div>
          </div>
        </div>
        <div className="carousel-footer-single-div">
          <div>
            <div className="rec-image-name">
              <div className="rec-image">
                <img src={lucija} />
              </div>
              <div className="rec-name">
                <span>Lucija Jakšić</span>
                <br />
                <span>23</span>
              </div>
            </div>

            <div className="divider"></div>
            <a target="_blank" href="https://g.co/kgs/BFwym9">
              <Stars />
            </a>
            <div className="rec-text">
              <p>“Izuzezno hrana i usluga. Za ne propustiti u Nišu.”</p>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default HomeFifthPart;
