import React, { useEffect, useContext, useState } from "react";
import { Form, Input, InputNumber, Button, Upload, message } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { connect, useDispatch } from "react-redux";
import { send_job_applay_data } from "../actions/contact_form";
import notificationFunc from "../components/functionNotification";

const Posao = ({ send_job_applay_data, isLoading }) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 8,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    let message_seent_job = send_job_applay_data(values);
    message_seent_job.then((m) => {
      if ((m.status = 200)) {
        notificationFunc("success", "Uspešno poslata poruka");
      } else {
        notificationFunc("error", "Došlo je do greške prilikom slanja poruke");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { width, height } = useWindowDimensions();

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  useEffect(() => {
    if (width < 576) {
      layout["wrapperCol"]["span"] = 16;
    } else {
      layout["wrapperCol"]["span"] = 8;
    }
  }, [width]);

  // const validateMessages = {
  //   required: "${label} je neophodno!",
  //   types: {
  //     email: "${label} nije validan!",
  //     number: "${label} nije validan!",
  //   },
  // };

  // const props = {
  //   onRemove: (file) => {
  //     const index = fileList.indexOf(file);
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     setFileList(newFileList);
  //   },
  //   beforeUpload: (file) => {
  //     setFileList([...fileList, file]);
  //     return false;
  //   },
  //   fileList,
  // };

  // const handleUpload = () => {
  //   const formData = new FormData();
  //   fileList.forEach((file) => {
  //     formData.append("files[]", file);
  //   });
  //   setUploading(true); // You can use any AJAX library you like

  //   fetch("https://www.mocky.io/v2/5cc8019d300000980a055e76", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then(() => {
  //       setFileList([]);
  //       message.success("upload successfully.");
  //     })
  //     .catch(() => {
  //       message.error("upload failed.");
  //     })
  //     .finally(() => {
  //       setUploading(false);
  //     });
  // };

  return (
    <div>
      <div className="main-div-job-contact">
        <h2 className="job-main-title">Postani deo kolektiva</h2>
        <h3 className="job-subtitle">
          Prijavi se za posao na poziciji koja tebi odgovara
        </h3>

        <div className="container-md form-container-custom">
          <Form
            name="basic"
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
            className="main-contact-form-div"
          >
            <Form.Item
              label="Ime"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Molimo Vas unesite ime!",
                },
              ]}
              className="main-contact-item-div"
            >
              <Input className="input-contact" placeholder="Ime" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Molimo Vas unesite email!",
                },
              ]}
              className="main-contact-item-div"
            >
              <Input className="input-contact" placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Komentar"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Molimo Vas unesite email!",
                },
              ]}
              className="main-contact-item-div"
            >
              <Input.TextArea
                className="input-contact"
                placeholder="Komentar"
              />
            </Form.Item>

            {/* <Form.Item
              name="file"
              label="CV"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="main-contact-item-div file-upload"
              rules={[
                {
                  required: true,
                  message: "Molimo Vas postavite Vaš CV!",
                },
              ]}
            >
              <Upload
                {...props}
                name="logo"
                action="/upload.do"
                listType="picture"
                className="file_uload"
              >
                <Button icon={<UploadOutlined />}>Klikni da dodaš fajl</Button>
              </Upload>
            </Form.Item> */}

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
              className="btn-div"
            >
              <Button
                type="primary"
                htmlType="submit"
                className="main-contact-item-button"
                // onClick={handleUpload}
              >
                Pošalji
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.contact_form,
  isLoading: state.contact_form,
});

export default connect(mapStateToProps, { send_job_applay_data })(Posao);
