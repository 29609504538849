import React from "react";
import logoIcon from "../assets/mainpage/parter2black.png";

const MagacinLocationPin = ({ text, link }) => {
  return (
    <div>
      <div className="pin">
        <a href={link} target="_blank">
          <img src={logoIcon} className="pin-icon" />
          <p className="pin-text">{text}</p>
        </a>
      </div>
    </div>
  );
};

export default MagacinLocationPin;
