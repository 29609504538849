import React from "react";
import partner from "../../assets/partner/partner.jpg";
import lcdbgo from "../../assets/partner/parter5.jpg";
import partner_image from "../../assets/partner/897A6535-2.jpg";
import puzzle_image from "../../assets/partner/4.jpg";
import { TiArrowRightOutline } from "react-icons/ti";

const MainFirstDiv = () => {
  return (
    <div className="main-container-partner">
      <div className="container-fluid custom-fluid">
        <div className="imgage-partner-holder">
          <img src={partner} className="img-partner-main" />
        </div>
      </div>
      <div className="postani-partner">
        <div className="img-logo-holder">
          <img src={lcdbgo} />
        </div>

        <div className="image-abs">
          <img src={partner_image} />
        </div>
        <div className="text-holder">
          <div className="red-circle-rel">
            <h3 className="depth">
              POSTAN! <br></br> naš partner
            </h3>
            <div className="red-circle-abs">
              <span>
                za <br></br>48<br></br>sati
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="asortiman-outer">
        <div className="container div-asortiman">
          <p>
            LCDB grab and Go, predstavlja ideju i kocpet, koji je osmišljen
            tako, da ko god bude želeo brz i kvalitetan obrok, isti dobije za
            nepuni minut u bilo kom partnerskom lokalu koji ima saradnju sa LCDB
            timom.
          </p>
          <p>
            <br></br>U Ponudi imamo 5 obrok salata, 3 wrepa, 3 različita
            napitka, 2 limunade i 1 slatkiš. Pišite nam za više informacija!
            <br></br>
          </p>
          <h5>Pogodnosti po vlasnike lokala:</h5>
          <ul>
            <li>
              <TiArrowRightOutline className="icon-c" />
              Nema finansijskih ulaganja
            </li>
            <li>
              <TiArrowRightOutline className="icon-c" />
              Imate širi asortiman
            </li>
            <li>
              <TiArrowRightOutline className="icon-c" />
              Dobijate jelovnik i spremljena jela,za čije serviranje vam nije
              potrebnakuhinja ili dodatan prostor
            </li>
            <li>
              <TiArrowRightOutline className="icon-c" />
              Gosti bivaju zadovoljniji, kompletnom uslugom
            </li>
            <li>
              <TiArrowRightOutline className="icon-c" />
              Mnogo više, zadovoljnih gostiju!
            </li>
          </ul>
        </div>
      </div>

      <div className="last-image-div">
        <img src={puzzle_image} />
      </div>
    </div>
  );
};

export default MainFirstDiv;
