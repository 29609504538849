import React, { useEffect, useContext, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { MainDivKontakt } from "../style/Kontakt";
import { InputNumber } from "antd";
import "../style/Contact.scss";
import MapComp from "../components/MapComp";
import { send_contact_form } from "../actions/contact_form";
import { connect, useDispatch } from "react-redux";
import notificationFunc from "../components/functionNotification";
import image1 from "../assets/mainpage/parter2.png";

const Kontakt = ({ send_contact_form, isLoading }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { width, height } = useWindowDimensions();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 8,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    let message_seent_job = send_contact_form(values);
    message_seent_job.then((m) => {
      console.log(m);
      if ((m.status = 200)) {
        notificationFunc("success", "Uspešno poslata poruka");
      } else {
        notificationFunc("error", "Došlo je do greške prilikom slanja poruke");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // console.log(layout["wrapperCol"]["span"]);

  useEffect(() => {
    if (width < 576) {
      layout["wrapperCol"]["span"] = 16;
    } else {
      layout["wrapperCol"]["span"] = 8;
    }
  }, [width]);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const location = {
    address: "Nikole Pašića",
    lat: 43.31776753204551,
    lng: 21.899549379153953,
  };

  const location2 = {
    address: "TC Delta Planet",
    lat: 43.32491527559158,
    lng: 21.911191979625674,
  };

  const defaultCenter = {
    lat: 43.32130819017357,
    lng: 21.90602548527343,
  };

  return (
    <div className="main-contanct-div">
      <div className="container form-div">
        <div className="container contact-form-text">
          <h2>Kontaktirajte nas</h2>
          <p>- Uputite nam svoje sugestije -</p>
        </div>
      </div>

      <div className="container-md form-container-custom">
        <Form
          name="basic"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          className="main-contact-form-div"
        >
          <Form.Item
            label="Ime"
            name="username"
            rules={[
              {
                required: true,
                message: "Molimo Vas unesite ime!",
              },
            ]}
            className="main-contact-item-div"
          >
            <Input className="input-contact" placeholder="Ime" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Molimo Vas unesite email!",
              },
            ]}
            className="main-contact-item-div"
          >
            <Input className="input-contact" placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Komentar"
            name="comment"
            rules={[
              {
                required: true,
                message: "Molimo Vas unesite email!",
              },
            ]}
            className="main-contact-item-div"
          >
            <Input.TextArea className="input-contact" placeholder="Komentar" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 24,
            }}
            className="btn-div"
          >
            <Button
              type="primary"
              htmlType="submit"
              className="main-contact-item-button"
            >
              Pošalji
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="map-div">
        <div className="container">
          <div className="map-text contact-form-text">
            <h2>Gde se nalazimo</h2>
          </div>
          <div className="map-of-div row">
            <div className="col-lg-9 col-12">
              <MapComp
                location={location}
                location2={location2}
                zoomLevel={15}
                defaultCenter={defaultCenter}
              />
            </div>
            <div className="contact-col-add col-lg-3 col-12">
              <div className="contact-addres-1 con-12">
                <h6>Adresa 1</h6>
                <p>Nikole Pašića 1, Niš 18000</p>
                <p>018 / 303-291</p>
                <p>email : lcdbnis@gmail.com</p>
              </div>
              <div className="contact-addres-1 col-13">
                <h6>Adresa 2</h6>
                <p>
                  TC Delta Planet, Bulevar Nemanjića 11b<br></br> Niš 18000
                </p>
                <p>018 / 309-294</p>
                <p>email : lcdbnis@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-info-div">
        <div className="heading-text"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.contact_form,
  isLoading: state.contact_form,
});

export default connect(mapStateToProps, { send_contact_form })(Kontakt);
