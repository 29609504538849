import GoogleMapReact from "google-map-react";
import React from "react";
import LocationPin from "./LocationPin";
import MagacinLocationPin from "./MagacinLocationPin";

const MapComp = ({ location, location2, zoomLevel, defaultCenter }) => {
  return (
    <div>
      <div
        className="map-div-loction"
        style={{ height: "60vh", width: "100%" }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAj9D2i508TB3Uq5DgpV3YpGOIZVwrV4MM" }}
          defaultCenter={defaultCenter}
          defaultZoom={zoomLevel}
        >
          <MagacinLocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
            link={
              "https://www.google.com/maps/place/La+casa+del+Burrito/@43.3177779,21.8995275,15z/data=!4m5!3m4!1s0x0:0xac180c92dbb5b9af!8m2!3d43.3177779!4d21.8995047"
            }
          />
          <LocationPin
            lat={location2.lat}
            lng={location2.lng}
            text={location2.address}
            link={
              "https://www.google.com/maps/place/La+casa+del+Burrito+TC+Delta+Planet/@43.3249113,21.9090215,17z/data=!3m1!4b1!4m5!3m4!1s0x4755b191f5e4e13f:0x88d9a387fa2fc561!8m2!3d43.3249074!4d21.9112102"
            }
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default MapComp;
