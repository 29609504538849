import React from "react";
import about_photo from "../assets/mainpage/about.jpg";

const HomeSixthPart = () => {
  return (
    <div id="onama" className="sixth-part">
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-12">
            <h2 className="main-title">O nama</h2>
            <div className="divider"></div>
            <div className="text-part-1">
              Tim mladih entuzijasta, dolazi na ideju da razdrma Niš, malo
              drugačijom i raznobojnijom kuhinjom, te da unese mnogo radosti u
              ponudu grada Niša.Ideja kreće da se realizuje onda kada je i
              osmišljena, februara 2020. godine, a sunce dana, LCDB je ugledala
              15. maja. Sunce ste bili svi vi koji ovo čitate, dragi gosti,
              žedni promene i novog ukusa.
            </div>
            <div className="text-part-2">
              Krenuli smo sa 6 ljudi i 3 proizvoda. Nakon godinu dana poslovanja
              i velikog interesovanja Nišlija, za naše proizvode, otvaramo i
              drugu lokaciju. Danas imamo 30+ kako stalno zaposlenih tako i
              eksternih saradnika, sa kojima svakodnevno radimo na unapređenju
              naših proizvoda, kojih je sada na jelovniku pregršt.
            </div>
          </div>
          <div className="about-photo-col col-md-5 col-12">
            <img src={about_photo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSixthPart;
