import axios from "axios";
import {
  CONTACT_FORM_SUCCESS,
  CONTACT_FORM_FAIL,
  CONTACT_FORM_LOADING,
  JOB_APPLY_SUCCESS,
  JOB_APPLY_FAIL,
  JOB_APPLY_LOADING,
} from "./types";
import { genericApi } from "./api";

export const send_contact_form = (data) => async (dispatch) => {
  dispatch({
    type: CONTACT_FORM_LOADING,
  });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(data);

    const body = {
      name: data.username,
      email: data.email,
      comment: data.comment,
    };

    console.log(body);

    const res = await genericApi().post("/api/v2/contact/contact-form/", body);

    console.log(res);

    dispatch({
      type: CONTACT_FORM_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: CONTACT_FORM_FAIL,
      error: error.response,
    });
    return error.response;
  }
};

export const send_job_applay_data = (data) => async (dispatch) => {
  dispatch({
    type: JOB_APPLY_LOADING,
  });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(data);

    const body = {
      name: data.username,
      email: data.email,
      comment: data.comment,
    };
    console.log(body);

    const res = await genericApi().post(
      "/api/v2/contact/job-apply-form/",
      body
    );

    // const res = await axios.post(
    //   `https://api.burrito.rs/api/v2/data/job-apply-form/`,
    //   body,
    //   config
    // );

    console.log(res);

    dispatch({
      type: JOB_APPLY_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: JOB_APPLY_FAIL,
      error: error.response,
    });
    return error.response;
  }
};
