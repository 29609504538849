import { Icon } from "@iconify/react";
import { locationIcon } from "@iconify/icons-mdi/map-marker";
import logoIcon from "../assets/mainpage/logo_front.png";

const LocationPin = ({ text, link }) => (
  <div className="pin">
    <a href={link} target="_blank">
      <img src={logoIcon} className="pin-icon" />
      <p className="pin-text">{text}</p>
    </a>
  </div>
);

export default LocationPin;
