import React from "react";

const MiddleYellow = () => {
  return (
    <div className="main-middle-yellow">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12 first-col">
            <div className="headline-1 head-p">
              <h2>
                Jesi li za <br /> Burrito?
              </h2>
            </div>
            <div className="divider-headline-1 head-1"></div>
            <div className="main-text main-text-2">
              <p>
                Najlakše je početi od burrita, i pitati jesi li za burrito, ali
                da li znate šta se sve nalazi u našoj širokoj paleti proizvoda?
              </p>
              <p>
                Pored klasične tex-mex varijante, street fooda, koja se ogleda u
                takosima, naćosima, chimichangama, mođete uživati u divnim obrok
                salatama, kao i slatkišima pripremanim u našoj kuhinji, po
                originalnim recepturama.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="headline-1 head-k">
              <h2 className="h2-sd">Kako sklopiti burrito?</h2>
            </div>
            <div className="divider-headline-1 head-2"></div>
            <div className="main-text">
              <p>
                Ti si taj ko stvara i koji bira. Sklopi ukuse tako da dobiješ
                savršenu kombinaciju.Ovde su 4 osnovna koraka a za sve savete,
                pitajte naše zaposlene, rado će vam pomoći!
              </p>
              <p>
                -Možeš birati da li želiš burrito u tortilji ili činiji. Zatim
                biraš osnovu, jednu vrstu pirinča, jednu vrstu pasulja i jednu
                vrstu mesa ili vege opciju. Nakon toga krećeš filovati svoj
                burrito, ukusima koje voliš, salaticama, salsama i sosevima. Na
                raspolaganju ti je uvek bar 15 različitih komponenti.Na kraju mi
                rolamo, ti klopaš!
              </p>
            </div>
          </div>
        </div>
        <div className="main-divider"></div>
        <div className="flex-col-ukoliko">
          <p>
            *Ukoliko nisi siguran ili klopaš prvi put kod nas, možeš se obratiti
            našim zaposlenima, uvek će biti spremni da ti rado preporuče pravu
            kombinaciju!
          </p>
        </div>
      </div>
    </div>
  );
};

export default MiddleYellow;
