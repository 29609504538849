import styled from "styled-components";
import image1 from "../assets/ketering/blob.svg";

export const MainDivKatering = styled.div`
  background: #f3dc0c;
  min-height: 100vh;
  padding: 180px 0px;

  .title {
    font-family: "Bevan";
    color: #000;
    font-size: 3.4rem;
    margin-bottom: 4rem;
    line-height: 5rem;
    text-align: center;
  }

  .content-wrapper {
    .slide-one {
      position: relative;
      height: fit-content;
      height: 400px;

      .image {
        width: 100%;
        /* height: 700px; */
        object-fit: cover;

        @media (max-width: 768px) {
          height: fit-content;
        }
      }
      .copyright-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .slide-text {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        .text-wrapper {
          padding: 50px 30px;
          .upper-text {
            padding: 0 100px;

            .left {
              font-size: 3rem;
              span {
                font-family: "Black Mango";
                font-size: 5.5rem;
                line-height: 3.8rem;
                color: #fff;
              }
              .small {
                font-size: 1.5rem;
                color: #fff;
                font-weight: 600;
                background: url(${image1});
              }
            }
          }
          .mid-text {
            display: flex;
            justify-content: space-between;

            @media (max-width: 768px) {
              flex-direction: column;
            }

            .slide {
              padding: 0 100px;

              @media (max-width: 1200px) {
                padding: 40px;
              }

              @media (max-width: 768px) {
                padding: 30px;
              }

              .slide-wrapper {
                padding: 40px;
                background: #ffffffe6;
                text-align: center;
                h3 {
                  font-size: 2rem;
                  .small-text {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }

          .lower-text {
            padding: 100px 100px;
            color: #000;
            .text-wrapper {
              background: #ffffffe6;

              .flex-container {
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
  }
`;
