import { Carousel, Modal } from "antd";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { HiArrowCircleRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import mainpagefirstphoto from "../assets/mainpage/landingpage.jpg";
import pic2 from "../assets/mainpage/slikamainpagesecond.jpg";
import pic3 from "../assets/mainpage/7.jpg";
import {
  CustomModal,
  DivHome,
  MainCustomModalHomePage,
  MainDiv,
  ModalWrapper,
} from "../style/Home";
import "../style/MainPage.scss";
import HomeEightPart from "./HomeEightPart";
import HomeFifthPart from "./HomeFifthPart";
import HomeFourthPart from "./HomeFourthPart";
import HomeSecondPart from "./HomeSecondPart";
import HomeSeventhPart from "./HomeSeventhPart";
import HomeSixthPart from "./HomeSixthPart";
import HomeMagacin from "./HomeMagacin";
import HomeThirdPart from "./HomeThirdPart";
import CustomModalHomePage from "./CustomModalHomePage";
import { connect, useDispatch } from "react-redux";
import { get_modal_data } from "../actions/get_modal_data";
import withRouter from "./withRouter";
import "../style/Home.scss";
import Cookies from "universal-cookie";

export const DimensionContext = createContext();

const Home = ({ modal, modalData }) => {
  const discCode = async () => {
    const results = await axios.get("/.netlify/functions/hello");
    console.log(results);
  };
  const dispatch = useDispatch();

  const cookies = new Cookies();
  const newsletter = cookies.get("newsletter");

  useEffect(() => {
    const _id = cookies.get("_ga");
    console.log(_id);
  }, []);

  useEffect(() => {
    dispatch(get_modal_data());
  }, []);

  function setCookieInterval() {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    console.log(date);
    return date;
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailValue, setEmailValue] = useState({
    email: "",
  });

  const { email } = emailValue;

  const onChange = (e) => {
    setEmailValue({ ...emailValue, [e.target.name]: e.target.value });
    console.log(emailValue);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log(emailValue.email);
    console.log("close modal");
    setIsModalVisible(false);
    cookies.set("newsletter", true);
  };

  const showModal = () => {
    console.log("asdasd");
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (newsletter == undefined || newsletter == "false") {
      setTimeout(() => {
        showModal();
      }, 1000);
    }
  }, []);

  const contentStyle = {
    height: "100vh",
    color: "#fff",
    // lineHeight: "400px",
    textAlign: "center",
    background: "#62929e",
  };

  const contentStyle2 = {
    height: "100vh",
    color: "#fff",
    textAlign: "center",
    background: "#fff",
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <AiOutlineArrowRight
        className={className}
        style={{
          ...style,
          right: "0",
          display: "block",
          color: "#f6f6f4",
          background: "rgba(0, 0 , 0, 0.1)",
          width: "40px",
          height: "60px",
          padding: "5px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <AiOutlineArrowLeft
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#f6f6f4",
          background: "rgba(0, 0 , 0, 0.1)",
          width: "40px",
          height: "60px",
          padding: "5px",
          zIndex: "3",
          top: "50%",
          transform: "translateY(-50%)",
          left: "0",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
  };

  const carouselRef = React.createRef();

  const [carouselChange, setChange] = useState(0);
  let timeoutCarousel = undefined;

  useEffect(() => {
    timeoutCarousel = setTimeout(() => {
      carouselRef && carouselRef.current && carouselRef.current.next();
      setChange(carouselChange + 1);
    }, 3500);
  }, [carouselChange]);

  const onMouseEnterDiv = () => {
    clearTimeout(timeoutCarousel);
  };

  const onMouseLeaveDiv = () => {
    setTimeout(() => {
      carouselRef && carouselRef.current && carouselRef.current.next();
      setChange(carouselChange + 1);
    }, 3500);
  };

  // <h3>Enter email adress</h3>
  // <input
  //   type="email"
  //   placeholder="Unesite email"
  //   name="email"
  //   value={email}
  //   onChange={(e) => onChange(e)}
  // />

  return (
    <>
      <DivHome className="home-div">
        <>
          {modal && (
            <CustomModal
              maskClosable={false}
              closable={true}
              title="Basic Modal"
              visible={isModalVisible}
              onOk={(e) => onSubmit(e)}
              onCancel={handleCancel}
              wrapClassName="modal-info"
              zIndex={1044}
              // footer={[<button className="okButton">OK</button>]}
              text_color={modal.text_color_hex}
              button_text_color_hex={modal.button_text_color_hex}
              button_bg_color_hex={modal.button_bg_color_hex}
              image={modal.background_image}
            >
              <MainCustomModalHomePage className="modal-div-div">
                {modal.text_visible && (
                  <div className="main-wrapper">
                    <div className="inner-wrapper">
                      <span className="modal-title">{modal.title}</span>
                      <br></br>
                      <span className="description">{modal.description}</span>
                      <br></br>
                      <span className="sub-description">
                        {modal.sub_description}
                      </span>
                      <button className="okButton" onClick={(e) => onSubmit(e)}>
                        OK
                      </button>
                    </div>
                  </div>
                )}
              </MainCustomModalHomePage>
            </CustomModal>
          )}
        </>
        <MainDiv>
          <Carousel arrows {...settings} ref={carouselRef}>
            <div
              onMouseEnter={() => {
                onMouseEnterDiv();
              }}
              onMouseLeave={() => {
                onMouseLeaveDiv();
              }}
              className="first-photo-landing-page first-photo"
            >
              <img className="first-photo" src={mainpagefirstphoto} />
              <div className="carousel-second-page-text">
                <h2>
                  <span className="bigger">Uvek </span>
                  <br></br>
                  Ukusno <br></br> Sveže <br></br> Drugačije
                </h2>
                <Link className="toburitolink" to="/burito">
                  <HiArrowCircleRight /> Istraži ukuse
                </Link>
              </div>
            </div>
            <div
              onMouseEnter={() => {
                onMouseEnterDiv();
              }}
              onMouseLeave={() => {
                onMouseLeaveDiv();
              }}
              className="first-photo-landing-page"
            >
              <img src={pic2} className="photo-align-rign custom-align-2" />
              <div className="carousel-second-page-text">
                <h2>
                  Jesi li <br></br> za Bur!to ?
                </h2>
                <Link className="toburitolink" to="/burito">
                  <HiArrowCircleRight /> Istraži ukuse
                </Link>
              </div>
            </div>
            <div
              onMouseEnter={() => {
                onMouseEnterDiv();
              }}
              onMouseLeave={() => {
                onMouseLeaveDiv();
              }}
              className="first-photo-landing-page"
            >
              <img src={pic3} className="custom-align-3" />
              <div className="carousel-second-page-text">
                <h2>Postani naš partner ?</h2>
                <Link className="toburitolink" to="/partner">
                  <HiArrowCircleRight /> Kontaktirajte nas
                </Link>
              </div>
            </div>
          </Carousel>
        </MainDiv>

        <HomeSecondPart />
        <HomeThirdPart />
        <HomeFourthPart />
        <HomeFifthPart />
        <HomeSixthPart />
        <HomeSeventhPart />
        <HomeMagacin />
        <HomeEightPart />

        {/* <HomeCarousel /> */}
        {/* <AktuelnaPonuda></AktuelnaPonuda> */}
        {/* <PopularnoKombinacije></PopularnoKombinacije> */}
      </DivHome>
    </>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.contact_form.isLoading,
  modal: state.modalData.data,
});

// export default Home;

export default withRouter(
  connect(mapStateToProps, {
    get_modal_data,
  })(Home)
);
