import React from "react";
import { FaStar } from "react-icons/fa";

const Stars = () => {
  return (
    <div className="row pt-2">
      <div className="col">
        <FaStar className="mr-1" style={{ color: "#f3dc0b" }} />
        <FaStar className="mr-1" style={{ color: "#f3dc0b" }} />
        <FaStar className="mr-1" style={{ color: "#f3dc0b" }} />
        <FaStar className="mr-1" style={{ color: "#f3dc0b" }} />
        <FaStar className="mr-1" style={{ color: "#f3dc0b" }} />
      </div>
    </div>
  );
};

export default Stars;
