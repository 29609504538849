import {
  GET_MODAL_FAIL,
  GET_MODAL_LOADING,
  GET_MODAL_SUCCESS,
} from "../actions/types";

const initialState = {
  loading: false,
  failed: false,
  http_response: {},
  data: {},
};

export default function (state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case GET_MODAL_LOADING:
      return {
        loading: true,
      };
    case GET_MODAL_FAIL:
      return {
        loading: false,
        failed: true,
        error: error,
        message: "Error",
      };

    case GET_MODAL_SUCCESS:
      return {
        loading: false,
        failed: false,
        message: "Success",
        data: payload,
      };
    default:
      return { ...state };
  }
}
