import React, { useState, useEffect } from "react";
import middlephoto from "../assets/mainpage/middlepage.jpg";
import slika1 from "../assets/mainpage/slika11.jpg";
import slika2 from "../assets/mainpage/slika4.jpg";
import slika3 from "../assets/mainpage/slika9.jpg";

import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Carousel } from "antd";

const HomeFifthPart = () => {
  useEffect(() => {
    setTimeout(() => {}, 1000);
  }, []);

  const [valueofsubmit, setValueofsubmit] = useState(false);

  const contentStyle = {
    height: "100vh",
    color: "#fff",
    // lineHeight: "400px",
    textAlign: "center",
    background: "#62929e",
  };

  const contentStyle2 = {
    height: "100vh",
    color: "#fff",
    textAlign: "center",
    background: "#fff",
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <AiOutlineArrowRight
        className={className}
        style={{
          ...style,
          right: "0",
          display: "block",
          color: "#f6f6f4",
          background: "rgba(0, 0 , 0, 0.1)",
          width: "40px",
          height: "60px",
          padding: "5px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <AiOutlineArrowLeft
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#f6f6f4",
          background: "rgba(0, 0 , 0, 0.1)",
          width: "40px",
          height: "60px",
          padding: "5px",
          zIndex: "3",
          top: "50%",
          transform: "translateY(-50%)",
          left: "0",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplaySpeed: 3500,
    dots: false,
  };

  const carouselRef = React.createRef();

  const [carouselChange, setChange] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      carouselRef && carouselRef.current && carouselRef.current.next();
      setChange(carouselChange + 1);
    }, 3000);
  }, [carouselChange]);
  return (
    <div className="fifth-part">
      <Carousel autoplay={true} arrows {...settings} ref={carouselRef}>
        <div className="first-photo-landing-page">
          <img src={middlephoto} className="middlephoto-custom-1" />
        </div>
        <div className="first-photo-landing-page ">
          <img src={slika1} className="middlephoto-custom-2" />
        </div>
        <div className="first-photo-landing-page">
          <img className="align-bottom middlephoto-custom-3" src={slika2} />
        </div>
        <div className="first-photo-landing-page">
          <img className="align-bottom middlephoto-custom-4" src={slika3} />
        </div>
      </Carousel>
    </div>
  );
};

export default HomeFifthPart;
