import axios from "axios";

const url = "http://localhost:8000";

export function genericApi() {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return axios.create({
    baseURL: url,
    headers: headers,
  });
}
