import React from "react";
import roloburito from "../assets/mainpage/foiled 1.png";

const HomeSecondPart = () => {
  return (
    <div className="second-part-home">
      <div className="container">
        <div className="rol-image">
          <img src={roloburito}></img>
        </div>
        <div className="text-rol-image">
          <p className="first-text">
            - Ono što se rola, a dobro je, obično je ilegalno -
          </p>
          <p className="second-text">Burrito je dozvoljen!</p>
        </div>
      </div>
    </div>
  );
};

export default HomeSecondPart;
