import React from "react";
import tortilja from "../assets/mainpage/ing/tortilja.jpg";
import source1 from "../assets/mainpage/ing/Bareni pirinac.jpg";
import source2 from "../assets/mainpage/ing/Meksicki pirinac.jpg";
import source3 from "../assets/mainpage/ing/Pinto.jpg";
import source4 from "../assets/mainpage/ing/Crni bio.jpg";
import source5 from "../assets/mainpage/ing/Alicante piletina.jpg";
import source6 from "../assets/mainpage/ing/Guadalope svinja.jpg";
import source7 from "../assets/mainpage/ing/Barbacou june.jpg";
import source8 from "../assets/mainpage/ing/Chilli con carne.jpg";
import source9 from "../assets/mainpage/ing/Fajita vege.jpg";
import source10 from "../assets/mainpage/ing/Kukuruz salsa.jpg";
import source11 from "../assets/mainpage/ing/Kackavlja.jpg";
import source12 from "../assets/mainpage/ing/Guacamole.jpg";
import source13 from "../assets/mainpage/ing/mix zelenih.jpg";
import source14 from "../assets/mainpage/ing/Marinirani kupus.jpg";
import source15 from "../assets/mainpage/ing/Marinirani luk.jpg";
import source16 from "../assets/mainpage/ing/Halopenjo.jpg";
import source17 from "../assets/mainpage/ing/Chipotle.jpg";
import source18 from "../assets/mainpage/ing/Vlasac.jpg";
import source19 from "../assets/mainpage/ing/salsa verde.jpg";
import source20 from "../assets/mainpage/ing/Blanko.jpg";
import source21 from "../assets/mainpage/ing/Nacho sos.jpg";
import img1 from "../assets/mainpage/11.jpg";
import img2 from "../assets/mainpage/12.jpg";
import img3 from "../assets/mainpage/13.jpg";
import img4 from "../assets/mainpage/14.jpg";
import img5 from "../assets/mainpage/15.jpg";
import img6 from "../assets/mainpage/16.jpg";
import img7 from "../assets/mainpage/17.jpg";
import img8 from "../assets/mainpage/18.jpg";
import img9 from "../assets/mainpage/19.jpg";
import img10 from "../assets/mainpage/20.jpg";
import img11 from "../assets/mainpage/21.jpg";
import img12 from "../assets/mainpage/22.jpg";

import BuritoComp from "../components/BuritoComp";
import IngComp from "../components/IngComp";

const HomeThirdPart = () => {
  const tort = [
    {
      id: 1,
      source: tortilja,
      nameIng: "Tortilja",
      desc: "Najfinija pšenična tortilja. -ukoliko želite gluten free varijantu, možete uzeti burrito u činiji a tortilju sa strane.",
    },
  ];

  const dataIng = [
    {
      id: 1,
      source: img5,
      nameIng: "Pirinač na pari sa pestom",
      desc: "-Pirinač kuvan na pari sa peršunom i uljem belog luka",
    },
    {
      id: 2,
      source: img11,
      nameIng: "Meksički pirinač",
      desc: "-(Aromatizovani pirinač koji nije ljut)",
    },
    {
      id: 3,
      source: img7,
      nameIng: "Pinto pasulj",
      desc: "-Šareni pasulj, sa suvim rebarcima",
    },
    {
      id: 4,
      source: img6,
      nameIng: "Crni BIO pasulj",
      desc: "–Zdrav BIO crni pasulj, sa kuminom",
    },
  ];

  const dataIng2 = [
    {
      id: 1,
      source: img4,
      nameIng: "Alicante piletina",
      desc: "Marinirani pileći batak, grilovan, seckan I začinjen",
    },
    {
      id: 2,
      source: img2,
      nameIng: "Guadalope svinejtina",
      desc: "Sporo krčkana svinjska plećka, sa korom pomorandže, autentično*",
    },
    {
      id: 3,
      source: img1,
      nameIng: "Barbacou junetina ",
      desc: "Junetina, spremana po tradicionačnom meksičko receptu, kuvana u sosu od 50 namirnica",
    },
    {
      id: 4,
      source: img3,
      nameIng: "Chilli con carne",
      desc: "Tradicionalno meksičko jelo, mlevena svinjetina I junetina",
    },
    {
      id: 5,
      source: img8,
      nameIng: "Fajita vege",
      desc: "Grilovani miks povrća uz doatak fajita meksičkog začina. Posno*",
    },
  ];

  const dataIng3 = [
    {
      id: 1,
      source: source10,
      nameIng: "Kukuruz salsa",
      desc: "Meksička salsa, paradajz, crveni luk, korijander, sok limete",
    },
    {
      id: 2,
      source: source11,
      nameIng: "Kačkavalj",
      desc: "Tradicionalni kremasti namaz od avokada",
    },
    {
      id: 3,
      source: source12,
      nameIng: "Guacamole",
      desc: "Tradicionalni kremasti namaz od avokada",
    },
    {
      id: 4,
      source: source13,
      nameIng: "Miz zelenih",
      desc: "Tradicionalan pikantan sos, na bazi paradajza",
    },
    {
      id: 5,
      source: source14,
      nameIng: "Marinirani kupus",
      desc: "Ljuta salsa, pravljena od domaćih ljutih I slatkih paprika, sa mentom I narandžom",
    },
    {
      id: 6,
      source: source15,
      nameIng: "Marinirani luk",
      desc: "Sos na bazi majoneza sa dimljenioom chipotle papričicom",
    },
    {
      id: 7,
      source: source16,
      nameIng: "Halopenjo",
      desc: "Srednje ljuta meksička papričica, marinirana",
    },
    {
      id: 8,
      source: source17,
      nameIng: "Chipotle",
      desc: "Sos na bazi majoneza sa dimljenioom chipotle papričicom",
    },
    {
      id: 9,
      source: source20,
      nameIng: "Crema blanko",
      desc: "Osvežavajuća krema na bazi grčkog jogurta sa belim lukom I peršunom",
    },
    {
      id: 10,
      source: source21,
      nameIng: "Nacho sos",
      desc: "Topljeni chedar sir, za potpuni uitak",
    },
    {
      id: 11,
      source: source18,
      nameIng: "Vlašac",
      desc: "Sos na bazi majoneza sa vlašcem",
    },
    {
      id: 12,
      source: source19,
      nameIng: "Salsa verde",
      desc: "Ljuta salsa, pravljena od domaćih ljutih i slatkih paprika, sa mentom i narandžom",
    },
    {
      id: 13,
      source: img12,
      nameIng: "Mokahete ",
      desc: "",
    },
    {
      id: 14,
      source: img9,
      nameIng: "Pico de gallo",
      desc: "Nezaobilazna salsa u Meksiku, bez koje ne prolazi praktično ni jedno jelo. Sastoji se od paradajza, crvenog luka, korijandera, peršuna I soka limuna.",
    },
  ];

  return (
    <div id="kakosta" className="third-part">
      <div className="container">
        <div className="main-title">
          <h2>
            Kako, <span>šta ?</span>
          </h2>
        </div>
        <div className="main-text">
          <p>
            Burrito je iskustvo, ne samo proizvod, koji na najbolji način
            simbolizuje i predstavlja duh meksilke kuhinje i njihov temperament.
            U Bukvalnom prevodu burrito-magarence, koje se tovari teretom. U
            našem slučaju je to tortilja, koju možete nafilovati sa preko 15
            salsi i soseva.
          </p>
        </div>
        <div className="steps-ingredients">
          <div className="list-of-steps">
            <h4 className="name-of-step">Korak 1</h4>
            <div className="step-divider"></div>

            <p className="description-of-step">
              Topla, sveža pšenična tortilja
            </p>
          </div>

          <div className="row justify-content-md-center">
            {tort.map((item, index) => {
              return (
                <div key={index} className="col-lg-2 col-md-4 col-6 ing-div">
                  <div className="inner-div-ingr">
                    <BuritoComp dataBur={item}></BuritoComp>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="steps-ingredients">
          <div className="list-of-steps">
            <h4 className="name-of-step">Korak 2</h4>
            <div className="step-divider"></div>

            <p className="description-of-step">
              Biraš osnovu, jedan pasulj i jedan pirinač
            </p>
          </div>

          <div className="row justify-content-md-center">
            {dataIng.map((item, index) => {
              return (
                <div key={index} className="col-lg-2 col-md-4 col-6 ing-div">
                  <div className="inner-div-ingr">
                    <BuritoComp dataBur={item}></BuritoComp>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="steps-ingredients">
          <div className="list-of-steps">
            <h4 className="name-of-step">Korak 3</h4>
            <div className="step-divider"></div>

            <p className="description-of-step">
              Ide glavni sastojak, nosilac imena burrita. Jednu vrstu mesa ili
              grilovano povrće
            </p>
          </div>

          <div className="row justify-content-md-center">
            {dataIng2.map((item, index) => {
              return (
                <div key={index} className="col-lg-2 col-md-4 col-6 ing-div">
                  <div className="inner-div-ingr">
                    <BuritoComp dataBur={item}></BuritoComp>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="steps-ingredients">
          <div className="list-of-steps">
            <h4 className="name-of-step">Korak 4</h4>
            <div className="step-divider"></div>
            <p className="container-sm description-of-step">
              Dodaj koliko god želiš salsi ili soseva, tako da začiniš i
              nafiluješ svoj burrito kako samo ti voliš!
            </p>
          </div>

          <div className="row justify-content-md-center">
            {dataIng3.map((item, index) => {
              return (
                <div key={index} className="col-lg-2 col-md-4 col-6 ing-div">
                  <div className="inner-div-ingr">
                    <BuritoComp dataBur={item}></BuritoComp>
                  </div>
                </div>
              );
            })}
          </div>
          <h4 className="name-of-step-last">Prepusti se i uživaj!</h4>
        </div>
      </div>
    </div>
  );
};

export default HomeThirdPart;
