import React from "react";
import landing from "../../assets/buritopage/firstdiv2.png";
import logo_black_white from "../../assets/logo/logo crno beli stroke.png";

const LandingDiv = () => {
  return (
    <div className="main-landing-page-bur">
      <div className="burito-page-first-div">
        <div className="overlay-black"></div>
        <div className="container custom-container-width">
          <div className="text-cont">
            <div className="text-1 text-bevan">
              <p>“Svet koji stvaramo u njemu i uživamo”</p>
            </div>
            <div className="text-1">
              <p>- Stvori Svet pun osmeha, poruči burrito za tebe i ekipu</p>
            </div>
            <div className="btn-cont">
              <button>
                Poruči <br />
                ODMAH
              </button>
            </div>
          </div>
        </div>

        <div className="img-container-landing">
          <img src={landing} />
        </div>
      </div>
      <div className="black-bg">
        <div className="container" style={{ zIndex: 20 }}>
          <h4>
            Love you from my head to my
            <span className="span-another-font"> burri-toes.</span>
          </h4>
        </div>

        <div className="logo-white">
          <img src={logo_black_white} />
        </div>
      </div>
    </div>
  );
};

export default LandingDiv;
