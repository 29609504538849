import React from "react";
import { Menu } from "antd";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  CustomBtnSidebar,
  LinkItemCustom2,
  MainSidebarDiv,
  MenuCustom,
  MenuItemCustom2,
  MenuItemCustom3,
} from "../style/Sidebar";
import { CustomBtn, LinkItemCustom, MenuItemCustom } from "../style/Navbar";

const { SubMenu } = Menu;

const Sidebar = (props) => {
  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  const [openKeys, setOpenKeys] = React.useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <MainSidebarDiv isOpen={props.isOpen}>
      <MenuCustom mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
        <MenuItemCustom2 onClick={props.toggle} key="1">
          <a
            href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
            target="_blank"
          >
            <CustomBtnSidebar>Poruči</CustomBtnSidebar>
          </a>
        </MenuItemCustom2>
        <MenuItemCustom3 onClick={props.toggle} key="2">
          <LinkItemCustom to="/#kakosta">
            <CustomBtnSidebar>Kako, šta</CustomBtnSidebar>
          </LinkItemCustom>
        </MenuItemCustom3>
        <MenuItemCustom3 onClick={props.toggle} key="3">
          <LinkItemCustom to="/burito">
            <CustomBtnSidebar>Je si li za Bur!to?</CustomBtnSidebar>
          </LinkItemCustom>
        </MenuItemCustom3>
        <MenuItemCustom3 onClick={props.toggle} key="4">
          <LinkItemCustom to="/#onama">
            <CustomBtnSidebar>O nama</CustomBtnSidebar>
          </LinkItemCustom>
        </MenuItemCustom3>
        <MenuItemCustom3 onClick={props.toggle} key="5">
          <LinkItemCustom to="/kontakt">
            <CustomBtnSidebar>Kontakt</CustomBtnSidebar>
          </LinkItemCustom>
        </MenuItemCustom3>
      </MenuCustom>
    </MainSidebarDiv>
  );
};

export default Sidebar;
