import React from "react";
import MainFirstDiv from "./MainFirstDiv";
import "../../style/Partner.scss";

const Partner = () => {
  return (
    <div>
      <MainFirstDiv />
    </div>
  );
};

export default Partner;
