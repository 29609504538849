import axios from "axios";
import { GET_MODAL_FAIL, GET_MODAL_LOADING, GET_MODAL_SUCCESS } from "./types";
import { genericApi } from "./api";

export const get_modal_data = () => async (dispatch) => {
  dispatch({
    type: GET_MODAL_LOADING,
  });

  try {
    const res = await genericApi().get("/api/v2/data/get_home_page_modal/");

    console.log(res);

    dispatch({
      type: GET_MODAL_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (error) {
    dispatch({
      type: GET_MODAL_FAIL,
    });

    return error.response;
  }
};
