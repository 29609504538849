import styled from "styled-components";
import { Link } from "react-router-dom";
import { Modal } from "antd";

export const MainDiv = styled.div`
  /* width: 100%; */
  min-height: 100vh;
  position: relative;
  /* background: red; */
`;

export const ImgBg = styled.img`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
`;

export const PageOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background: black;
  opacity: 0;
`;

export const IzPonude = styled.div`
  width: 100%;
  height: 100vh;
`;

export const PosetiteNas = styled.div`
  width: 100%;
  height: 100vh;
`;

export const DivAutoSlider = styled.div`
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/VAK_6131.jpg?alt=media&token=67c8ce95-bb36-4302-b39a-7ebe3ca0f2e2"); */
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
`;

export const DivAutoSlider2 = styled.div`
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/VAK_6107.jpg?alt=media&token=db336a11-d261-456a-bc53-cfa8be69f314"); */
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
`;
export const DivHome = styled.div`
  /* width: 100%; */
  background: #f3dc0ce5;
`;

export const HomePonuda = styled.div`
  width: 100vw;
  height: 1000px;
  background-size: cover;
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/bghome1.png?alt=media&token=96b8032b-1bf1-4b5e-8e98-844c32008e34"); */
`;
export const Popularno = styled.div`
  width: 100%;
  padding: 50px 0 160px 0;
`;

export const HomeAboutBurito = styled.div`
  padding: 50px 0 40px 0;
`;

export const ButtonDonesiHome = styled(Link)`
  color: #f3dc0b;
  border: 1px #f3dc0b solid;
  font-family: "Bellota Text", cursive;
  font-size: 28px;
  padding: 8px 20px 8px 20px;
  transition: 0.4s all ease;

  &:hover {
    color: black;
    background-color: #f3dc0b;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const IzdvajamoLink = styled(Link)`
  color: black;
  border: 1px solid black;
  font-family: "Bellota Text", cursive;
  font-size: 28px;
  padding: 8px 20px 8px 20px;
  transition: 0.4s all ease;
  overflow-wrap: break-word;

  &:hover {
    color: #f3dc0b;
    background-color: black;
  }
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const Slider2Div1 = styled.div`
  background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/IMG_6163.png?alt=media&token=9e7fe5b0-f29f-4c3b-8913-80f467a94e4d");
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
`;

export const Slider2Div2 = styled.div`
  background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/1.png?alt=media&token=1471d016-2c5b-4765-8c46-04a765372700");
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
`;

export const Slider2Div3 = styled.div`
  background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/IMG_6175.png?alt=media&token=c568edc9-7707-42c9-8044-7ae10ca08715");
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
`;

export const Slider2Div4 = styled.div`
  background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/IMG_6204.png?alt=media&token=a74135f7-f1c9-4d60-a54e-ec2a32105e4a");
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
`;

export const Slider2Div5 = styled.div`
  background-image: url("https://firebasestorage.googleapis.com/v0/b/burito-9d736.appspot.com/o/IMG_6251.png?alt=media&token=dcdb42ef-09a3-447d-8b9d-87d61bd59225");
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
`;

export const LinkLanding = styled(Link)`
  color: #f3dc0b;
  border: 1px #f3dc0b solid;
  font-family: "Bellota Text", cursive;
  font-size: 28px;
  padding: 8px 20px 8px 20px;
  -webkit-transition: 0.4s all ease;
  transition: 0.4s all ease;

  &:hover {
    color: black;
    background: #f3dc0b;
  }
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const MainCustomModalHomePage = styled.div`
  width: 100%;
  height: 100%;
`;

export const CustomModal = styled(Modal)`
  .okButton {
    border: none;
    padding: 5px 20px 2px;
    font-size: 1.2rem;
    font-family: "Bevan";
    color: ${({ button_text_color_hex }) =>
      `${button_text_color_hex} !important`};
    background: ${({ button_bg_color_hex }) =>
      `${button_bg_color_hex} !important`};
    margin-top: 30px;
  }
  .ant-modal-header {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-content {
    box-shadow: none !important;
  }

  background-image: ${({ image }) => {
    return `url("https://api.burrito.rs/${image}")`;
  }};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f4df25d8;
  top: 200px;
  padding-bottom: 0;
  border: 2px solid #f4e025;
  min-height: 500px;

  .ant-modal-close-x {
    color: ${({ text_color }) => `${text_color} !important`};
  }

  .ant-modal-content {
    border-radius: 0;
    background: transparent;
    text-align: center;

    .ant-modal-body {
      color: ${({ text_color }) => `${text_color} !important`};

      span {
        font-family: "Bevan";
        font-family: "Bellota Text";
        font-weight: 500;
        padding-top: 20px;
      }

      .modal-title {
        margin-top: 30px;
        font-size: 2.2rem;
      }

      .description {
        padding-top: 20px;
        font-size: 1.3rem;
      }

      .sub-description {
        font-size: 1.3rem;
      }
    }
  }
`;
