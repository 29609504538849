import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import CarouselComponent2 from "../components/CarouselComponent2";

const HomeFooter = () => {
  return (
    <div>
      <div className="main-footer-container container-fluid">
        <div className="row">
          <div className="col-footer-first col-lg-4 col-md-6 col-12">
            <h4 className="lscb-title">LCDB</h4>
            <div className="row">
              <div className="col-6">
                <div className="menu-items">
                  <div className="menu-item">
                    <a href="/#kakosta"> Kako, sta?</a>
                  </div>
                  <div className="footer-item-divider"></div>
                  <div className="menu-item">
                    <Link to="/burito"> Jelovnik</Link>
                  </div>
                  <div className="footer-item-divider"></div>

                  <div className="menu-item">
                    <a href="/#onama">O nama</a>
                  </div>
                  <div className="footer-item-divider"></div>

                  <div className="menu-item">
                    <Link to="/magacin"> Projekti</Link>
                  </div>
                  <div className="footer-item-divider"></div>

                  <div className="menu-item">
                    <Link to="/kontakt"> Kontakt</Link>
                  </div>
                  <div className="footer-item-divider"></div>
                </div>
              </div>
              <div className="sm-links col-6">
                <div className="link-instagram">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/burritocasa/?hl=en"
                  >
                    <AiOutlineInstagram />
                  </a>
                </div>
                <div className="link-fb">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/La-casa-del-Burrito-111295003799259/"
                  >
                    <AiOutlineFacebook />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-footer-second col-lg-4 col-md-6 col-12">
            <CarouselComponent2 />
          </div>
          <div className="col-footer-third col-lg-4 col-md-12 col-12 footer-apply-picture">
            <Link to="/posao">
              <span className="link-kolektiv">
                GRADI SVOJU KARIJERU KOD NAS
              </span>
              <div className="link-kolektiv-divider"></div>
              <span className="link-kolektiv-2">prijavi se!</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
