import styled from "styled-components";
import { Link } from "react-router-dom";
import { Menu, Button } from "antd";

export const LinkItem = styled(Link)`
  color: black;
  text-decoration: none;
`;

export const MainSidebarDiv = styled.div`
  display: block;
  position: fixed;
  z-index: 1020;
  width: 100vw;
  height: 100vh;
  background: #f3dc0b;
  padding-top: 80px;
  transition: 0.5s all ease;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "100%")};
  left: ${({ isOpen }) => (isOpen ? "0%" : "100%")};
`;

export const MenuCustom = styled(Menu)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 80px;
`;

export const CustomBtnSidebar = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: "Bevan";
`;

export const LinkItemCustom2 = styled(Link)`
  font-family: "Bevan", cursive;
  font-style: normal;
  font-size: 22px;
  padding: 5px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const MenuItemCustom2 = styled(Menu.Item)`
  font-family: "Bevan", cursive;
  margin-top: 10px;
`;

export const MenuItemCustom3 = styled(Menu.Item)`
  font-family: "Bevan", cursive;
  margin-top: 20px !important;
`;
