import React from "react";
import { Link } from "react-router-dom";
import magacin from "../assets/magacin/magacinlogo.png";

const HomeMagacin = () => {
  return (
    <div>
      <Link to={"/magacin"}>
        <div className="img-of-magacin-div">
          <img className="img-of-magacin" src={magacin} />
        </div>
      </Link>
    </div>
  );
};

export default HomeMagacin;
