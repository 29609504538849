import React from "react";
import parter1 from "../assets/mainpage/parter1.jpg";
import parter2 from "../assets/mainpage/parter2.png";
import parter3 from "../assets/mainpage/parter3.jpg";
import parter4 from "../assets/mainpage/parter4.png";
import parter5 from "../assets/mainpage/parter5.jpg";
import Carousel from "react-elastic-carousel";

const HomeEightPart = () => {
  return (
    <div className="partners">
      <div className="main-container container-fluid">
        <div className="main-overlay"></div>
        <div className="global-slider">
          <div className="row">
            <div className="partner-col col-lg-3 col-md-6 col-6">
              <img src={parter1} />
            </div>
            <div className="partner-col col-lg-3 col-md-6 col-6">
              <img src={parter2} />
            </div>
            <div className="partner-col col-lg-3 col-md-6 col-6">
              <img src={parter4} />
            </div>
            <div className="partner-col col-lg-3 col-md-6 col-6">
              <img src={parter5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeEightPart;
