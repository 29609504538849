import React from "react";
import round_picture from "../assets/mainpage/35 1 (1).png";
import burito_icon from "../assets/mainpage/hero-burrito-lg 1.png";

const HomeFourthPart = () => {
  return (
    <div className="fourth-part">
      <div className="container">
        <a
          style={{ color: "black" }}
          href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
          target="_blank"
        >
          <div className="row">
            <div className="divided-row col-lg-6 col-12">
              <div className="pictures-relative">
                <div className="text-absolute">
                  <div className="text-meni-lcdb-relative">
                    <span className="lcdb">LCDB</span>
                    <span className="meni">Meni</span>
                  </div>
                </div>
                <div className="pictures-absolute">
                  <img src={round_picture} />
                </div>
              </div>
            </div>
            <div className="divided-row div-2 col-lg-6 col-12">
              <div className="title-burrito">
                <img className="burito-icon" src={burito_icon} />
                <span className="main-title">Jesi li za Burrito?</span>
              </div>
              <div className="title-divider"></div>
              <div className="main-text">
                <p>
                  Najlakše je početi od burrita, i pitati jesi li za burrito,
                  ali da li znate šta se sve nalazi u našoj širokoj paleti
                  proizvoda?!
                  <br /> Pored klasične tex-mex varijante, street fooda, koja se
                  ogleda u takosima, naćosima, chimichangama, mođete uživati u
                  divnim obrok salatama, kao i slatkišima pripremanim u našoj
                  kuhinji, po originalnim recepturama.
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default HomeFourthPart;
