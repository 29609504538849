import { GrContact } from "react-icons/gr";
import styled from "styled-components";

export const BuritoDiv = styled.div`
  background: url("../assets/mainpage/texturehomebg1.jpg");
  padding-top: 80px;
  text-align: center;
`;

export const DivBuritoSastojci = styled.div`
  padding-top: 80px;
`;

export const GrContactStyled = styled(GrContact)`
  path {
    /* color: #fff !important;
    fill: #fff !important; */
    stroke: #fff;
  }
`;
