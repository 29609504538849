import React, { useContext, useState } from "react";
import { Routes, Route, BrowserRouter, Router } from "react-router-dom";
import Home from "./containers/Home";
import Kontakt from "./containers/Kontakt";
import Layout from "./containers/Layout";
import "antd/dist/antd.css";
import { CookiesProvider } from "react-cookie";
import MainMenuPage from "./containers/MenuPage/MainMenuPage";
import Partner from "./containers/Partner/Partner";
import Magacin from "./containers/Magacin/Magacin";
import Posao from "./containers/Posao";
import { Provider } from "react-redux";
import { store } from "./store";
import Ketering from "./containers/Ketering";
import { Switch } from "react-router";

function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <Layout>
          <Routes>
            <Route path="/burito" element={<MainMenuPage />} />
            <Route path="/partner" element={<Partner />} />
            <Route path="/magacin" element={<Magacin />} layout={<Layout />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/posao" element={<Posao />} />
            {/* <Route path="/ketering" element={<Ketering />} /> */}
            <Route
              path="/glovo"
              element={() => {
                window &&
                  window
                    .open(
                      "https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/",
                      "_blank"
                    )
                    .focus();

                return <div></div>;
              }}
            />
            <Route path="/" element={<Home />} />
          </Routes>
        </Layout>
      </Provider>
    </CookiesProvider>
  );
}

export default App;
