import React, { useEffect, useState } from "react";
import image1 from "../../assets/page2/6 1.png";
import image2 from "../../assets/page2/42 1.png";
import image3 from "../../assets/page2/43 1.png";
import image4 from "../../assets/page2/21 1.png";
import image5 from "../../assets/page2/41 1.png";
import image6 from "../../assets/page2/aqfr 1.png";
import { CgMenuRight } from "react-icons/cg";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { Modal } from "antd";
import { GrContactStyled } from "../../style/Burito";
import { GrContact } from "react-icons/gr";

const Jelovnik = () => {
  const cookies = new Cookies();

  function setCookieInterval() {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    console.log(date);
    return date;
  }

  useEffect(() => {
    let isVisited = cookies.get("employeeDiscount");

    if (
      isVisited == undefined ||
      isVisited == null ||
      isVisited == false ||
      isVisited == "undefined"
    ) {
      showModal();
    }
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    cookies.set("employeeDiscount", true, {
      expires: setCookieInterval(),
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    cookies.set("employeeDiscount", true, {
      expires: setCookieInterval(),
    });
  };

  return (
    <>
      <div className="moda">
        <Modal
          className="modal-zaposleni"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText={"Otkaži"}
          maskClosable={false}
        >
          <h3>Želiš li najbolju klopu za svoje zaposlene</h3>
          <h4>- Do 15% popusta za firme -</h4>
          <h5>
            Ukoliko ste zainteresovani za saradnju možete nas kontaktirati putem
            kontakt
            <Link to="/kontakt">
              <span>forme</span> <GrContactStyled />
            </Link>
          </h5>
        </Modal>
      </div>
      <div className="main-main">
        <div className="main-container-jelovnik">
          <div className="container-xl">
            <div className="list-of-d">
              <div className="row">
                <div className="col-lg-2 col-md-6 col-12">
                  <a className="locallink" href="#burito">
                    <span>BURR!TO</span>
                  </a>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <a className="locallink" href="#tacos">
                    <span>TACOS</span>
                  </a>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <a className="locallink" href="#ques">
                    <span>QUESADILLA</span>
                  </a>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <a className="locallink" href="#salata">
                    <span>SALATA</span>
                  </a>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <a className="locallink" href="#tapas">
                    <span>TAPAS</span>
                  </a>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <a className="locallink" href="#napici">
                    <span>
                      NAPICI
                      <br /> <span className="smaller">100% prirodni</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="burito" className="main-container-dish-single">
          <div className="img-of-dish">
            <img src={image1} />
            <div className="img-overlay-right"></div>
            <div className="text-on-image">
              <h3>BURR!TO</h3>
              <span className="salata-text">
                SVEŽE NAMIRNICE <br /> OD LOKALNIH
              </span>
              <br />
              <span className="salata-text">proizvođača</span>
            </div>
          </div>
          <div className="orange-text">
            <div className="container">
              <p>
                Burrito kao simbol imena koje nosimo, predstavlja proizvod u
                tortilji, koji dobija zapravo ime po imenu mesa koje je unutra.
                Tako kod nas u ponudi možete pronaći:Alicante Piletinu,
                Guadalope sivnjetinu, Barbacoa junetinu, Chilli con carne i
                fajita vege. Pre odabira mesa, birate “bazu”, koju čini jedan od
                dve vrste pirinča i jedan od dve vrste pasulja. Kombinacijom i
                dodavanjem ostalih sastojaka formirate ukus burrita.
              </p>
              <p>
                *Ukoliko ste prvi put kod nas i ne želite da prepuštate stvari
                slučaju, obratite se nekom od naših zaposlenih i on će vam
                napraviti kombinaciju kojoj ne možete odoleti a po vašem ukusu!
              </p>
              <div className="jelovnik-btn">
                <a
                  className="link-jelovnik"
                  href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
                  target="_blank"
                >
                  <button>
                    <CgMenuRight /> <span>Jelovnik</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <div id="tacos" className="main-container-dish-single">
          <div className="img-of-dish">
            <img src={image2} />
            <div className="img-overlay-right"></div>
            <div className="text-on-image">
              <h3>
                TACO<span className="black-s">S</span>
              </h3>

              <span className="black-text-bellow-name">
                NEODOLJIVI <br /> MUSKEtARI
              </span>
              <br />
              <span className="black-text-bellow-name">lcdb tima!</span>
            </div>
          </div>
          <div className="orange-text">
            <div className="container">
              <p>
                Burrito kao simbol imena koje nosimo, predstavlja proizvod u
                tortilji, koji dobija zapravo ime po imenu mesa koje je unutra.
                Tako kod nas u ponudi možete pronaći:Alicante Piletinu,
                Guadalope sivnjetinu, Barbacoa junetinu, Chilli con carne i
                fajita vege. Pre odabira mesa, birate “bazu”, koju čini jedan od
                dve vrste pirinča i jedan od dve vrste pasulja. Kombinacijom i
                dodavanjem ostalih sastojaka formirate ukus burrita.
              </p>
              <p>
                *Ukoliko ste prvi put kod nas i ne želite da prepuštate stvari
                slučaju, obratite se nekom od naših zaposlenih i on će vam
                napraviti kombinaciju kojoj ne možete odoleti a po vašem ukusu!
              </p>
              <div className="jelovnik-btn">
                <a
                  className="link-jelovnik"
                  href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
                  target="_blank"
                >
                  <button>
                    <CgMenuRight /> <span>Jelovnik</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="ques" className="main-container-dish-single">
          <div className="img-of-dish">
            <img src={image3} />
            <div className="img-overlay-right"></div>
            <div className="text-on-image">
              <h3>QUESADILLA</h3>
              <span className="black-text-bellow-name">
                MNOGO <br /> mnogo <br></br> S!RA
              </span>
              <br />
            </div>
          </div>
          <div className="orange-text">
            <div className="container">
              <p>
                Quesadilla, predstavlja jedno od omiljenih specijaliteta svih
                zaljubljenika u meksičku kuhinju ali ne ostavlja ravnodušne ni
                sv eone koji prvi put probaju ovo. <br /> Šta je quesadilla?
                <br />
                Tortilja, preklopljena na pola, spolja zapečena na puteru a
                unutra punjena topljenim kačkavaljem, mesom i nekim sosom ili
                salsom, zavisno od toga koju kesadilju odaberte. Uvek možete
                uzeti dodatan sosić sa strane, kako bi vaš ušitak bio poptuniji!
                <br />
                Ukoliko ste pasionirani ljubitelj sira, možete pitati naše
                zaposlene za extra dodatak unutar same ksadilje, koji će vaše
                iskustvo, učiniti još potpunijim.
              </p>

              <div className="jelovnik-btn">
                <a
                  className="link-jelovnik"
                  href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
                  target="_blank"
                >
                  <button>
                    <CgMenuRight /> <span>Jelovnik</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="salata" className="main-container-dish-single">
          <div className="img-of-dish">
            <img src={image4} />
            <div className="img-overlay-right"></div>
            <div className="text-on-image">
              <h3>
                OBROK <br /> SALATA
              </h3>
              <span className="salata-text">
                KAKO SALATE <br />
                MOGU BITI
                <br />
                UKUSNIJE
                <br />
                OD MESA
              </span>
            </div>
          </div>
          <div className="orange-text">
            <div className="container">
              <p>
                Obrok salate kakve ne možete ni da zamislite!Kombinacija voća i
                sezonskog povrća, sa mesom i dressingom koji je proizvod znanja
                i umeća kombinovanja sa maštom, daje rezultate poput Remolache,
                Avokanda, Fiš zeleniša ili vege salate.
                <br />
                <br />
                Autentiče i dobro izbalansirane salate, tu su da vam obezbede
                dovoljno energije za normalno funkcionisanje tokom dana.
              </p>

              <div className="jelovnik-btn">
                <a
                  className="link-jelovnik"
                  href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
                  target="_blank"
                >
                  <button>
                    <CgMenuRight /> <span>Jelovnik</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="tapas" className="main-container-dish-single">
          <div className="img-of-dish">
            <img src={image5} />
            <div className="img-overlay-right"></div>
            <div className="text-on-image">
              <h3>
                MEXICAN <br></br> TAPAS
              </h3>
              <span className="salata-text">
                DOPUNA <br></br> I ZALOGAJI <br></br> KOJI SE PAMTE
              </span>
            </div>
          </div>
          <div className="orange-text">
            <div className="container">
              <p>
                I ako tapasi zapravo predstavljaju španske zalogaje, sve ono što
                se može konzumirati kao meze ili hrana na zalogaje, mi smo to
                modifikovali i napravili meksičke!<br></br>
                <br></br>Ukoliko vam treba dopuna, ili niste sasvim sigurni da
                vam je dovoljno ukusa iz naše kuhinje, već bi ste još jedan i
                još da ga podelite sa nekim, izaberite nešto iz ove
                kategorije.Tu su spadaju jela poput: Patatasa, čimičangi, vege
                rolnica, tortilja čipsa i enčilada.Dodavanjem mesa preko nekih
                od ovih tapasa možete dobiti kompletan obrok, koji će vas
                zasititi!<br></br>Preporuka svakako ide za patatas bravas +
                Alicante piletina!
              </p>

              <div className="jelovnik-btn">
                <a
                  className="link-jelovnik"
                  href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
                  target="_blank"
                >
                  <button>
                    <CgMenuRight /> <span>Jelovnik</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="napici" className="main-container-dish-single">
          <div className="img-of-dish">
            <img src={image6} />
            <div className="img-overlay-right"></div>
            <div className="text-on-image">
              <h3>
                aqua <br></br> <span className="green-text">FRESCA</span>
              </h3>
              <span className="black-text-bellow-name">
                UKUS PRIRODE <br></br> BEZ ŠEĆERA<br></br> I KONZERVANSA
              </span>
            </div>
          </div>
          <div className="orange-text">
            <div className="container">
              <p>
                Aqua fresca predstavlja naš prvi brend, napravljen u sklopu La
                casa del burrita. To su napici na prirodnoj bazi, od celih
                plodova voća i povrća, gde se ekstrahovanjem njihovih sokova i
                mežusobnom kombinacijom istih, dobijaju zdravi napici.<br></br>
                Trenutno u ponudi imamo:<br></br>Sunrise (Šargarepa, narandža,
                limun)
                <br></br>Mint (Ananas, jabuka, limeta, menta)<br></br>Crvena
                (Cvekla, jabuka, djumbir, ljumun)<br></br>
                <br></br>Limunada sa mentom<br></br>Limunada sa malinom<br></br>
                <br></br>*Aqa fresca je kao i svi naši proizvodi, pravi se istog
                dana kada se stavlja u prodaju, te je uvek sveža a kada se lepo
                rashladi, pogodna je i za pravljenje koktela.
              </p>

              <div className="jelovnik-btn">
                <a
                  className="link-jelovnik"
                  href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
                  target="_blank"
                >
                  <button>
                    <CgMenuRight /> <span>Jelovnik</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jelovnik;
