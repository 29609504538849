import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { MainDiv } from "../style/Home";
import Cookies from "universal-cookie";
import HomeFooter from "./HomeFooter";
import { matchRoutes, useLocation } from "react-router-dom";
import CookiesMessage from "./CookiesMessage";

const Layout = (props) => {
  const cookies = new Cookies();
  const [isNewsletter, setIsNewsletter] = useState(false);
  const [shouldShowNewsletter, setShoudShowNewsletter] = useState(true);

  const [isMagacin, setIsMagacin] = useState(false);
  const location = useLocation();

  // const magacinPath = location && location.pathname;

  const magacinPath = location && location;

  function setCookieInterval() {
    let today = new Date();
    let hh = today.getHours();
    let newDate = new Date(today.setHours(hh * 24 * 7));
    return newDate;
  }
  const dateCookieExpires = setCookieInterval();

  useEffect(() => {
    const newsletter = cookies.get("newsletter");

    if (newsletter == false) {
      setShoudShowNewsletter(true);
    } else if (newsletter == undefined) {
      setShoudShowNewsletter(false);
      cookies.set("newsletter", isNewsletter, { expires: dateCookieExpires });
      setIsNewsletter(true);
    } else if (newsletter == true) {
      setShoudShowNewsletter(false);
      cookies.set("newsletter", isNewsletter, { expires: dateCookieExpires });
      setIsNewsletter(true);
    }

    // cookies.set("newsletter", isNewsletter, { expires: dateCookieExpires });
  }, []);

  const [opened, setOpened] = useState(false);
  const [cookiesAccepted, setcookiesAccepted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // setOpened(true);
    }, 3000);
  }, []);

  return (
    <MainDiv className="layout-div">
      {magacinPath !== "/magacin" && <Navbar props={props} />}
      <Sidebar />
      <div>{props.children}</div>
      <HomeFooter />
      <CookiesMessage opened={opened} />
    </MainDiv>
  );
};

export default Layout;
