import React, { useEffect, useState } from "react";
import "../../style/Magacin.scss";
import magacin from "../../assets/magacin/magacinlogo.png";
import burger_bg from "../../assets/magacin/magacinburger.jpg";
import { Link, useMatch } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
// import { useParams, useRoutes } from "react-router";
import parter1 from "../../assets/mainpage/parter1.jpg";
import parter2 from "../../assets/mainpage/parter2.png";
import parter3 from "../../assets/mainpage/parter3.jpg";
import parter4 from "../../assets/mainpage/parter4.png";
import parter5 from "../../assets/mainpage/parter5.jpg";

const Magacin = (layout) => {
  // const params = useMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(layout);

  return (
    <div>
      <div className="main-div-magacin">
        <div className="main-container container-magacin-overlay container-fluid">
          <div className="main-overlay"></div>
          <div className="global-slider slider-magacin-div">
            <div className="row">
              <div className="partner-col col-magacin col-lg-3 col-md-6 col-12">
                <img src={parter1} />
              </div>
              <div className="partner-col col-magacin col-lg-3 col-md-6 col-12">
                <img src={parter2} />
              </div>
              <div className="partner-col col-magacin col-lg-3 col-md-6 col-12">
                <img src={parter4} />
              </div>
              <div className="partner-col col-magacin col-lg-3 col-md-6 col-12">
                <img src={parter5} />
              </div>
            </div>
          </div>
        </div>
        <div className="main-div-logo">
          <img src={magacin} />
        </div>
      </div>

      <div className="burger-div-1">
        <div className="for-bg"></div>

        <div className="container text-div">
          <h3>MAGACIN BURGER BAR</h3>
          <div className="magacin-divider"></div>
          <h6>Projekat #01</h6>
        </div>

        <div className="inner-magacin-orange-div">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-12 align-right">
                MA<br></br>GA<br></br>CIN
              </div>

              <div className="col-md-10 col-12 text-magacin">
                <p>
                  burger bar, predstavlja jedan od prvih projekata u našoj
                  zamisli i izvedbi.
                </p>

                <p>
                  Inspirisani okruženjem u kome burgeri postaju sve atraktivniji
                  a usled nedostatka kvalitetnog materijala u ponudi, rešili smo
                  da upotrebimo svoju kreativnost i maštu i napravimo nešto
                  drugačije.Principi kojima se vodio ceo tim mladih i kreativnih
                  stručnih ljudi, ali i kojima ćemo se ubuduće voditi jeste
                  kvalitet ali i kvantitet, bez obzira što mnogi mislili da te
                  dve stvari ne mogu ići zajedno.
                </p>
                <p>
                  Osmišljavanje koncepta, kreiranje neophodnih normativa i
                  dokumentacije, organizacija operacija i rada u kuhinji,
                  kreiranje nabavke i prodaja, jesu bile naše dužnosti.Uz to,
                  naš tim je radio i na brendingu kao i na marketinškim
                  aktivnostima.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="burger-page-div-2">
        <div className="text-over-burger">
          <h1 className="white">KVALITETNO</h1>
          <h1>SVEŽE</h1>
          <h1 className="white">UKUSNO</h1>
          <h2>
            <AiOutlineArrowRight />{" "}
            <a
              href="https://glovoapp.com/rs/sr/nis/magacinburgerbar/"
              target="_blank"
            >
              jelovnik
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Magacin;
