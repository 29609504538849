export const CONTACT_FORM_SUCCESS = "CONTACT_FORM_SUCCESS";
export const CONTACT_FORM_FAIL = "CONTACT_FORM_FAIL";
export const CONTACT_FORM_LOADING = "CONTACT_FORM_LOADING";

export const JOB_APPLY_SUCCESS = "JOB_APPLY_SUCCESS";
export const JOB_APPLY_FAIL = "JOB_APPLY_FAIL";
export const JOB_APPLY_LOADING = "JOB_APPLY_LOADING";

export const GET_MODAL_SUCCESS = "GET_MODAL_SUCCESS";
export const GET_MODAL_FAIL = "GET_MODAL_FAIL";
export const GET_MODAL_LOADING = "GET_MODAL_LOADING";
