import React from "react";
import { MainDivCookiesMessage } from "../style/Navbar";

const CookiesMessage = ({ opened }) => {
  return (
    <MainDivCookiesMessage className="cookies" opened={opened}>
      <div className="cookies-message-wrapper">
        <div className="container">
          <div className="d-flex flex-container">
            <div className="text"></div>
            <div className="button"></div>
          </div>
        </div>
      </div>
    </MainDivCookiesMessage>
  );
};

export default CookiesMessage;
