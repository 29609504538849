import React, { useEffect, useState } from "react";
import {
  CustomBtn,
  CustomBtnOrder,
  CustomBtnOrder2,
  LinkItemCustom,
  MenuDiv,
  MenuItemCustom,
  NavbarDiv,
  LinkItemCustom2,
} from "../style/Navbar";
import { IoIosArrowDropdown } from "react-icons/io";
import { LinkItem } from "../style/Sidebar";
import { Menu, Dropdown, Button, Space } from "antd";
import logo from "../assets/logo/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from "./Sidebar";
import { Cookies, useCookies } from "react-cookie";
import "../style/MainPage.scss";
import { useWindowScroll } from "react-use";
import { Link as PageLink } from "react-scroll";

const Navbar = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [iconRotate, setIconRotate] = useState(false);
  const [isScrolled, setIsScrolled] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const { y: pageYOffset } = useWindowScroll();

  // const location = props && props.location && props.location.pathname;
  const location = props && props.location && props.location;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    // console.log(scrollPosition);
    if (scrollPosition < 100) {
      setIsScrolled(true);
      // console.log(isScrolled);
    } else {
      setIsScrolled(false);
      // console.log(isScrolled);
    }

    setScrollPosition(pageYOffset);
  }, [pageYOffset]);

  const toggle = () => {
    console.log("adasd");
    setIsOpen(!isOpen);
    setIconRotate(!iconRotate);
  };

  const sidebaropen = () => {
    console.log("sidebar");
  };

  const rest_of_pages = () => {
    return (
      <>
        <NavbarDiv scrolled={isScrolled}>
          <div className="logo-div">
            <a href="/">
              <img className="logo-img" src={logo}></img>
            </a>
          </div>
          <div className="navbar">
            <ul className="navbar-ul">
              <a href="/#kakosta">
                <CustomBtn>Kako, šta?</CustomBtn>
              </a>
              {/* <LinkItemCustom to="/meni">
              <Dropdown overlay={menu} placement="bottomCenter">
                <CustomBtn className="navbar-menu-link">
                  Meni
                  <IoIosArrowDropdown
                    style={{ marginLeft: "6px", marginBottom: "5px" }}
                  />
                </CustomBtn>
              </Dropdown>
            </LinkItemCustom> */}

              <LinkItemCustom to="/burito">
                <CustomBtnOrder2>
                  <span>Jesi li za</span>
                  <br />
                  <span className="bold-text">Burrito?!</span>
                </CustomBtnOrder2>
              </LinkItemCustom>
              {/* <LinkItemCustom to="/ketering">
                <CustomBtnOrder2>
                  <span>Ketering</span>
                </CustomBtnOrder2>
              </LinkItemCustom> */}
              <LinkItemCustom2 href="/#onama">
                <CustomBtn>O nama</CustomBtn>
              </LinkItemCustom2>
              <LinkItemCustom to="/kontakt">
                <CustomBtn>Kontakt</CustomBtn>
              </LinkItemCustom>
              <LinkItemCustom2
                href="https://glovoapp.com/rs/en/nis/la-casa-del-burrito/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <CustomBtnOrder>
                  <span>PORUČI </span>
                  <br /> <span className="asd">dostavu</span>
                </CustomBtnOrder>
              </LinkItemCustom2>
            </ul>
          </div>
          <div className="relative-div-menu">
            <GiHamburgerMenu
              onClick={toggle}
              className="icon-menu"
            ></GiHamburgerMenu>
          </div>
          <div className="real-exp">
            <p>
              Real <span className="mex">mexican</span> experience.
              <br /> Nish, since 2020 <sup>©</sup>
            </p>
          </div>
        </NavbarDiv>
        <Sidebar toggle={toggle} isOpen={isOpen} />
      </>
    );
  };

  const burito_page = () => {
    return (
      <>
        <NavbarDiv scrolled={isScrolled}>
          <div className="logo-div">
            <a href="/">
              <img className="logo-img" src={logo}></img>
            </a>
          </div>
          <div className="burito-link-div">
            <LinkItemCustom to="/burito">
              <CustomBtnOrder2>
                <span>Jesi li za</span>
                <br />
                <span className="bold-text">Burrito?!</span>
              </CustomBtnOrder2>
            </LinkItemCustom>
          </div>
          <div className="navbar">
            <ul className="navbar-ul">
              <a href="/#kakosta">
                <CustomBtn>Kako, šta?</CustomBtn>
              </a>
              {/* <LinkItemCustom to="/meni">
              <Dropdown overlay={menu} placement="bottomCenter">
                <CustomBtn className="navbar-menu-link">
                  Meni
                  <IoIosArrowDropdown
                    style={{ marginLeft: "6px", marginBottom: "5px" }}
                  />
                </CustomBtn>
              </Dropdown>
            </LinkItemCustom> */}

              <LinkItemCustom2 href="/#onama">
                <CustomBtn>O nama</CustomBtn>
              </LinkItemCustom2>
              <LinkItemCustom to="/kontakt">
                <CustomBtn>Kontakt</CustomBtn>
              </LinkItemCustom>
              <LinkItemCustom2
                href="https://glovoapp.com/rs/sr/nis/la-casa-del-burrito/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <CustomBtnOrder>
                  <span>PORUČI </span>
                  <br /> <span className="asd">dostavu</span>
                </CustomBtnOrder>
              </LinkItemCustom2>
            </ul>
          </div>
          <div className="relative-div-menu">
            <GiHamburgerMenu
              onClick={toggle}
              className="icon-menu"
            ></GiHamburgerMenu>
          </div>
          {/* <div className="real-exp">
            <p>
              Real <span className="mex">mexican</span> experience.
              <br /> Nish, since 2020 <sup>©</sup>
            </p>
          </div> */}
        </NavbarDiv>
        <Sidebar toggle={toggle} isOpen={isOpen} />
      </>
    );
  };

  return (
    <>
      {(location == "/burito") & (width > 992)
        ? burito_page()
        : rest_of_pages()}
    </>
  );
};

export default Navbar;
