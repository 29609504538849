import styled from "styled-components";
import { Menu, Dropdown, Button, Space } from "antd";
import { Link } from "react-router-dom";
import navbarbg from "../assets/navbar/SVG/asd.svg";

export const NavbarDiv = styled.div`
  width: 100%;
  height: 150px;
  position: fixed;
  background: url(${navbarbg});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  /* background-color: ${({ isScrolled }) => (isScrolled ? "red" : "green")}; */
  display: flex;
  justify-content: space-between;
  z-index: 1031;
  font-family: "Bellota Text", cursive;
  /* position: relative; */
`;

export const CustomBtn = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: none;
  font-size: 1.2rem;
  font-family: "Bellota Text", cursive;
  word-wrap: break-word;
  border-radius: 12px;

  span {
    padding-bottom: 10px;
  }

  &:hover {
    background: black;
    color: #f3dc0b;
  }

  &:focus {
    background: black;
    color: #f3dc0b;
  }
`;

export const CustomBtnOrder = styled(Button)`
  background: #00000066;
  border: none;
  box-shadow: none;
  font-size: 1.4rem;
  height: auto;
  font-family: "Bellota Text", cursive;
  word-break: break-word;
  line-height: 1.2rem;
  padding: 12px 28px 8px;
  border-radius: 12px;
  color: #fff;

  button {
    &:hover {
      background-color: #000000cc !important;
      color: #fff !important;
      box-shadow: 0 0 10px 10px #0000001a;
    }
  }

  .asd {
    font-size: 1rem;
  }

  &:hover {
    background: black;
    color: #f3dc0b;
  }

  &:active,
  &:focus {
    background-color: #000000cc !important;
    color: #fff !important;
    box-shadow: 0 0 10px 10px #0000001a;
  }
`;

export const CustomBtnOrder2 = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: none;
  font-size: 1.2rem;
  height: auto;
  font-family: "Bellota Text", cursive;
  word-break: break-word;
  line-height: 1.5rem;
  border-radius: 12px;

  span.bold-text {
    font-weight: bold;
    font-size: 1.45rem;
  }

  &:hover {
    background: black;
    color: #f3dc0b;
  }

  &:active,
  &:focus {
    background-color: #000000cc !important;
    color: #fff !important;
    box-shadow: 0 0 10px 10px #0000001a;
  }
`;

export const MenuDiv = styled(Menu)`
  background: #f3dc0b;
  padding: 0;
  transition: 0.2 all ease;
  /* width: 200px; */
`;

export const MenuItemCustom = styled(Menu.Item)`
  background: transparent;
  transition: 0.2 all ease;
  padding: 8px 15px 3px 15px;

  &:hover {
    background: black;
    color: #f3dc0b;
  }
`;

export const LinkItemCustom = styled(Link)`
  font-family: "Bellota Text", cursive;
  padding: 5px;
  margin-top: 10px;
  display: contents;
  font-weight: bold;

  button {
    font-family: "Bellota Text", cursive;
    font-weight: bold;
  }

  span {
    font-family: "Bellota Text", cursive;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  &:hover {
    background: transparent;
    color: black;
  }
`;

export const LinkItemCustom2 = styled.a`
  font-family: "Bellota Text", cursive;
  font-style: normal;
  font-weight: 400;
  padding: 5px;

  button {
    font-family: "Bellota Text", cursive;
  }

  span {
    font-family: "Bellota Text", cursive;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  &:hover {
    background: transparent;
    color: black;
  }
`;

export const MainDivCookiesMessage = styled.div`
  position: fixed;
  width: 100vw;
  height: 200px;
  background: #f3dc0ce5;
  box-shadow: 0px -20px 25px #00000044;
  transition: 0.4s all ease;
  bottom: ${({ opened }) => {
    return opened ? "0" : "-200px";
  }};
  left: 0;
`;
