import React, { useEffect } from "react";
import LandingDiv from "./LandingDiv";
import "../../style/BuritoPage.scss";
import MiddleYellow from "./MiddleYellow";
import Jelovnik from "./Jelovnik";

const MainMenuPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-menu-page">
      <LandingDiv />
      <MiddleYellow />
      <Jelovnik />
    </div>
  );
};

export default MainMenuPage;
