import { Button, Popover } from "antd";
import styled from "styled-components";

export const ButtonCustom = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: 0;
  font-size: 20px;
  align-items: center;
  color: #f3dc0b;
  text-align: center;
  /* line-height: 30px; */
`;
export const ButtonCustom2 = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: 0;
  font-size: 20px;
  align-items: center;
  color: #000;
  text-align: center;
  position: absolute;
  right: -15px;
  top: -10px;
  /* line-height: 30px; */

  &:hover {
    color: #000 !important;
    background-color: transparent !important ;
    box-shadow: none !important;
    border: none !important;
  }

  &:active,
  &:focus,
  &:target {
    border: none !important;
    background: transparent !important;
    color: #000;
    box-shadow: none !important;
  }

  svg::after {
    border: none !important;
    box-shadow: none !important;
    animation: none !important ;
  }
`;

export const CustomPopover = styled(Popover)`
  max-width: 200px;
  height: ;
  /* background-color: #f3dc0b; */
`;
